import React from 'react';
import {useLocation, useParams} from 'react-router-dom';
import Navbar from '../components/NavBar';
import Footer from '../components/Footer';
import {openInAppBanner} from '../helpers';
import Card from '../components/Card';
import {useData} from '../components/DataProvider';
import {Helmet} from 'react-helmet';
import SEO from '../components/SEO';

const PosePage = () => {
  const {id} = useParams();
  const location = useLocation();
  const {data} = useData();

  console.log(data);

  // Check if posePublicData is available in the location's state
  const posePublicDataFromLocation = location.state?.posePublicData;

  // If not found in location's state, find it in the DataProvider's data
  const posePublicData = posePublicDataFromLocation || data.posesPublicData.find(pose => pose.id === id);

  return (
    <div className='pose-page'>
      <SEO
        title={`${posePublicData.name.sanskrit} (${posePublicData.name.en})`}
        description={`Explore detailed information on ${posePublicData.name.sanskrit.trim()} (${posePublicData.name.en.trim()}) with insights into pose types, themes, tags, benefits, chakras, and variations.`}
        keywords="asanas, yoga poses, yoga benefits, yoga chakras"
        canonicalUrl={`https://www.sequence-studios.com/asanas/${posePublicData.id}`}
      />
      <Navbar banner={openInAppBanner} />
      <h1 className="sr-only">{`${posePublicData.name.sanskrit.trim()} (${posePublicData.name.en.trim()})`}</h1>

      <div className="mt-10 pt-30 md:pt-20 pb-20 flex flex-col">
        <div className="flex flex-col items-center justify-center overflow-visible">
          <div className='min-w-[320px] max-w-[450px]'>
            <Card id={id} posePublicData={posePublicData} />
          </div>

          {posePublicData?.description?.en ? (
            <p className="w-3/4 md:w-1/3 mt-20 font-inter text-base font-light leading-relaxed text-left whitespace-pre-line">
              {posePublicData.description.en.trim()}
            </p>
          ) : null}

          {posePublicData?.funFacts && posePublicData.funFacts.length > 0 && (
            <div className="fun-facts w-3/4 md:w-1/3 mt-8">
              <h3 className='font-inter text-base font-light leading-relaxed text-left whitespace-pre-line mb-4'>{`Here're some fun facts about ${posePublicData.name.sanskrit}`}</h3>
              <ul>
                {posePublicData.funFacts.map((fact, index) => (
                  <li className='font-inter text-base font-light leading-relaxed text-left whitespace-pre-line py-1' key={index}>{fact.en}</li>
                ))}
              </ul>
            </div>
          )}

          <p className="w-3/4 md:w-1/3 mt-8 font-inter text-base font-light leading-relaxed text-left whitespace-pre-line">
            Download the Sequence app to explore comprehensive information on asanas, including details about chakras, benefits, and more.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PosePage;
