import React from 'react';
import { ReactComponent as ArrowRightSVG } from '../resources/icons/arrow-right.svg'; // Import your SVG icon

const ArrowRight = () => {
  return (
    <div>
      <ArrowRightSVG width="22" height="22" /> {/* Adjust width and height as needed */}
    </div>
  );
};

export default ArrowRight;
