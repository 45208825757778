import React from 'react';
import { ReactComponent as ArrowUpRightSVG } from '../resources/icons/arrow-up-right.svg'; // Import your SVG icon

const ArrowUpRight = () => {
  return (
    <div>
      <ArrowUpRightSVG width="22" height="22" /> {/* Adjust width and height as needed */}
    </div>
  );
};

export default ArrowUpRight;
