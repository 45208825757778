/* eslint-disable import/first */
import { initializeApp } from 'firebase/app';
import {getFirestore} from 'firebase/firestore';
import {getStorage} from 'firebase/storage';
import { getAnalytics } from "firebase/analytics";

import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
  Navigate,
  useParams,
} from "react-router-dom";

const firebaseConfig = {
  apiKey: "AIzaSyC_NsTLueGmLWHU5rLuw0UvAkDssfY0B5c",
  authDomain: "yogiquence.firebaseapp.com",
  projectId: "yogiquence",
  storageBucket: "yogiquence.appspot.com",
  messagingSenderId: "184371624323",
  appId: "1:184371624323:web:5746c0eeab52eae6ac5eb6",
  measurementId: "G-GXD13T9936"
};

// Initialize Firebasexs
const firebaseApp = initializeApp(firebaseConfig);
export const db = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);
export const analytics = getAnalytics(firebaseApp);

import TermsOfService from "./pages/terms";
import PrivacyPolicy from "./pages/privacy";
import TermsAndPrivacy from "./pages/termsAndPrivacy";
import Home from "./pages/home";
import ErrorPage from "./pages/errorPage";
import SequencePage from "./pages/sequencePage";
import PosePage from "./pages/posePage";
import PosesPage from './pages/poses';

function App() {

  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/asana" element={<PosePage />} />
      {/* <Route path="/pose" element={<Navigate replace to="/asana" />} /> */}
      <Route path="/asana/:id" element={<PosePage />} />
      <Route path="/pose/:id" element={<RedirectToAsana />} />
      <Route path="/asanas" element={<PosesPage />} />
      <Route path="/poses" element={<Navigate replace to="/asanas" />} />
      <Route path="/sequence" element={<SequencePage />} />
      <Route path="/sequence/:id" element={<SequencePage />} />
      <Route path="/terms-of-service" element={<TermsOfService />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-and-privacy" element={<TermsAndPrivacy />} />
      <Route exact path="*" status={404} element={<ErrorPage/>} />
    </Routes>
  );
}

function RedirectToAsana() {
  const { id } = useParams();
  return <Navigate to={`/asana/${id}`} replace />;
}

export default App;
