import PrivacyPolicy from "./privacy";
import TermsOfService from "./terms";

function TermsAndPrivacy() {
    return (
        <div>
            <PrivacyPolicy/>
            <TermsOfService/>
        </div>
    )
}

export default TermsAndPrivacy;