import React from 'react';
import PropTypes from 'prop-types';

const Skeleton = ({ numberOfLines, color, lineHeight, padding }) => (
  <div className="animate-pulse space-y-4">
    {Array.from({ length: numberOfLines }).map((_, index) => (
      <div key={index} className={`${lineHeight} ${color} ${padding} rounded w-full`}></div>
    ))}
  </div>
);

Skeleton.propTypes = {
  numberOfLines: PropTypes.number,
  color: PropTypes.string,
  lineHeight: PropTypes.string,
};

Skeleton.defaultProps = {
  numberOfLines: 3,
  color: 'bg-gray-300',
  lineHeight: 'h-4', // Default line height class
  padding: 'py-1'
};

export default Skeleton;
