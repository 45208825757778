import React, { useState } from 'react';
import ArrowRight from './ArrowRight';
import ArrowUpRight from './ArrowTopRight';

const SecondaryButton = ({ text, showArrow, onClick }) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <button
            className="flex items-center px-6 py-2 bg-transparent text-black border border-transparent cursor-pointer transition-colors duration-300"
            onClick={onClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <span className="font-inter text-base font-medium hover:underline">{text}</span>
            {showArrow && (
                <div className="ml-2">
                    {isHovered ? <ArrowRight /> : <ArrowUpRight />}
                </div>
            )}
        </button>
    );
};

export default SecondaryButton;
