import React from 'react';

const HorizontalImageTextComponent = ({ title, subtitle, imageSrc, reverse }) => {
  return (
    <div className="flex flex-col lg:flex-row mx-auto w-full lg:w-[70%] py-10">
      <div className="flex-[400]">
        <img src={imageSrc} alt="Descriptive" className="w-full h-auto" />
      </div>
      <div className=" flex-[240] mt-4 lg:ml-10 flex flex-col justify-center">
        <h1 className="text-black font-inter font-medium text-lg mb-2">{title}</h1>
        <p className="text-gray-600 font-inter font-normal text-lg">{subtitle}</p>
      </div>
    </div>
  );
};

export default HorizontalImageTextComponent;
