import React from 'react';
import { useParams } from 'react-router-dom';
import Navbar from '../components/NavBar';
import Footer from '../components/Footer';
import {openInAppBanner} from '../helpers';

const SequencePage = () => {
  const { id } = useParams();
  console.log(id);

  return (
    <div className='sequence-page'>
      <Navbar banner={openInAppBanner} />
      <div className="px-4 md:px-20">
        <h1 className="text-black font-inter font-medium text-lg mb-2 py-40">To view this sequence open the link from your phone when Sequence is installed.</h1>
      </div>
      <Footer />
    </div>
  );
};

export default SequencePage;
