import React, {useState} from 'react';
import ArrowRight from './ArrowRight';
import ArrowUpRight from './ArrowTopRight';

const VerticalImageTextComponent = ({imageSrc, text, title, subtitle, button}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className="flex flex-col items-center text-center mb-20">
      <div className="w-full flex justify-center">
        <img src={imageSrc} alt="Descriptive" className="w-full max-w-full h-auto rounded-3xl" />
      </div>
      <div className="w-3/4 md:w-1/3 mt-12">
        {text ? (
          <p className="font-inter text-base font-light leading-relaxed text-left whitespace-pre-line">{text}</p>
        ) : (
          <div className="text-left">
            <h1 className="text-black font-inter font-medium text-lg mb-2">{title}</h1>
            <p className="text-gray-600 font-inter font-normal text-lg">{subtitle}</p>
          </div>
        )}
        {
          button ?
            <button
              className="flex items-center mt-5 bg-transparent text-black border border-transparent cursor-pointer transition-colors duration-300"
              onClick={button.action}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <span className="font-inter text-lg font-medium hover:underline">{button.text}</span>
              <div className="ml-2">
                {isHovered ? <ArrowRight /> : <ArrowUpRight />}
              </div>
            </button>
            :
            null
        }
      </div>
    </div>
  );
};

export default VerticalImageTextComponent;
