import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {getDownloadURL, ref} from "firebase/storage";
import {storage} from '../App';
import {fetchPosePublicData} from '../managers/DatabaseManagerHelpers';
import Skeleton from './Skeleton';

const Card = ({id, posePublicData, rotate, onClick}) => {
    const containerClassNames = {
        "-3": "card rounded-xl overflow-hidden bg-white shadow-xl border border-gray-100 transform rotate-[-3deg]",
        "-2.5": "card rounded-xl overflow-hidden bg-white shadow-xl border border-gray-100 transform rotate-[-2.5]",
        "-2": "card rounded-xl overflow-hidden bg-white shadow-xl border border-gray-100 transform rotate-[-2deg]",
        "-1.5": "card rounded-xl overflow-hidden bg-white shadow-xl border border-gray-100 transform rotate-[-1.5deg]",
        "-1": "card rounded-xl overflow-hidden bg-white shadow-xl border border-gray-100 transform rotate-[-1deg]",
        "-0.5": "card rounded-xl overflow-hidden bg-white shadow-xl border border-gray-100 transform rotate-[-0.5deg]",
        "0": "card rounded-xl overflow-hidden bg-white shadow-xl border border-gray-100 transform rotate-[0deg]",
        "0.5": "card rounded-xl overflow-hidden bg-white shadow-xl border border-gray-100 transform rotate-[0.5deg]",
        "1": "card rounded-xl overflow-hidden bg-white shadow-xl border border-gray-100 transform rotate-[1deg]",
        "1.5": "card rounded-xl overflow-hidden bg-white shadow-xl border border-gray-100 transform rotate-[1.5deg]",
        "2": "card rounded-xl overflow-hidden bg-white shadow-xl border border-gray-100 transform rotate-[2deg]",
        "2.5": "card rounded-xl overflow-hidden bg-white shadow-xl border border-gray-100 transform rotate-[2.5deg]",
        "3": "card rounded-xl overflow-hidden bg-white shadow-xl border border-gray-100 transform rotate-[3deg]",
    }

    const [loading, setLoading] = useState(false);
    const [statePosePublicData, setStatePosePublicData] = useState(null);
    const [poseImageUrl, setPoseImageUrl] = useState(null);
    const [chips, setChips] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        async function fetchImageUrl(imagePath) {
            const cachedUrl = localStorage.getItem(imagePath);
            if (cachedUrl) {
                setPoseImageUrl(cachedUrl);
                return;
            }

            try {
                const storageRef = ref(storage, imagePath);
                const url = await getDownloadURL(storageRef);
                setPoseImageUrl(url);
                localStorage.setItem(imagePath, url);
            } catch (err) {
                setError(err);
            }
        }

        function generateChips(posePublicData) {
            let newChips = [];

            if (posePublicData.hasTwoSides) {
                newChips = [...chips, "2 Sides"];
            }

            for (const chip of posePublicData.themes) {
                newChips = [...newChips, chip];
            }

            if (newChips.length > 2) {
                newChips = newChips.filter(chip => chip !== "Forward Bend");
            }

            newChips = newChips.slice(0, 4);

            setChips(newChips);
        }

        async function fetchData() {
            if (statePosePublicData) {
                if (!poseImageUrl && statePosePublicData.imageRef) {
                    fetchImageUrl(statePosePublicData.imageRef);
                }
                return;
            }

            if (posePublicData) {
                setStatePosePublicData({...posePublicData});
                generateChips({...posePublicData});
                return;
            }

            setLoading(true);

            const tmpPosePublicData = await fetchPosePublicData(id);

            setStatePosePublicData(tmpPosePublicData);

            if (tmpPosePublicData.imageRef) {
                fetchImageUrl(tmpPosePublicData.imageRef);
            }

            generateChips(tmpPosePublicData);
            setLoading(false);
        }

        fetchData();
    }, [posePublicData, statePosePublicData, poseImageUrl, id, chips]);
    
    return (
        <div
        className={`card-container p-6`}
        >
            <div
                className={`${containerClassNames[rotate || "-2.5"]}`}
                onClick={onClick}
            >
                <div className="image-placeholder p-[20px] aspect-[1/1]" >
                    {
                        poseImageUrl ?
                            <img src={poseImageUrl} alt="Card" className="w-full h-full rounded-xl object-contain bg-gray-100 p-4" /> :
                            <div className="w-full h-full rounded-xl object-contain bg-gray-100 p-4 animate-shimmer bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200 bg-[length:200%_100%]" />
                    }

                </div>
                <div className="pl-[20px] pr-[20px] pb-[20px]">
                    <div className='title py-1'>
                        {
                            loading ? <Skeleton numberOfLines={1} lineHeight={"h-7"} /> : <h2 className="text-xl font-medium line-clamp-1">{statePosePublicData?.name.sanskrit}</h2>
                        }
                    </ div>
                    <div className='subtitle py-1'>
                        {
                            loading ? <Skeleton numberOfLines={1} lineHeight={"h-5"} /> : <p className="text-base font-medium text-[#5A5A5A] line-clamp-1">{statePosePublicData?.name.en}</p>
                        }
                    </ div>
                    <div className='description py-1'>
                        {
                            loading ? <Skeleton numberOfLines={2} lineHeight={"h-4"} /> : <p className="text-sm text-[#7A7A7A] py-1 line-clamp-2 min-h-[48px]">{statePosePublicData?.description?.en || "\n\n"}</p>
                        }
                    </ div>
                    <div className='chips py-1'>
                        {
                            loading ?
                                <Skeleton numberOfLines={1} lineHeight={"h-4"} color={"bg-turquoise"} /> :
                                <div className="flex flex-wrap-nowrap overflow-x-auto">
                                    {chips.map((chip, index) => (
                                        <span
                                            key={index}
                                            className="bg-[#C5EAE1] rounded-md px-2 py-1 text-xs font-normal mr-2 mb-2 whitespace-nowrap"
                                        >
                                            {chip}
                                        </span>
                                    ))}
                                </div>
                        }
                    </ div>
                </div>
            </div>
        </div>

    );
};

// Card.propTypes = {
//     id: undefined | null | PropTypes.string,
//     posePublicData: undefined | null | PropTypes.shape({
//         description: PropTypes.shape({
//             en: PropTypes.string
//         }),
//         hasTwoSides: PropTypes.bool,
//         id: PropTypes.string,
//         imageRef: PropTypes.string,
//         name: PropTypes.shape({
//             en: PropTypes.string,
//             saEn: PropTypes.string | undefined,
//             sanskrit: PropTypes.string | undefined,
//         }),
//         themes: PropTypes.arrayOf(PropTypes.string).isRequired
//     }),
//     rotate: undefined | null | PropTypes.string,
//     onClick: undefined | null | PropTypes.func
// };

export default Card;
