import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import PrimaryButton from './PrimaryButton';
import SecondaryButton from './SecondaryButton';
import {openInNewTab} from '../helpers';
import {AppStoreUrl, FacebookUrl, InstagramUrl, PlayStoreUrl} from '../constants';
import {logEvent} from 'firebase/analytics';
import {analytics} from '../App';

const Footer = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const handleHomeClick = () => {
        if (location.pathname === '/') {
            window.scrollTo({top: 0, behavior: 'smooth'});
        } else {
            navigate('/');
        }
    };

    return (
        <div
            className="footer h-180 bg-cover bg-center bg-no-repeat flex flex-col justify-between text-white text-xl py-5 px-4 md:px-20"
            style={{backgroundImage: `url('/resources/images/footer-background.avif')`}}
        >
            <div className="top-section flex flex-col sm:flex-row flex-wrap justify-between rounded-lg py-5">
                <div className="left-content flex-1 text-left mb-4 md:mb-0 md:mr-4">
                    <p className="yoga-your-way text-black font-inter text-4xl font-medium leading-tight mb-6">
                        Yoga, your way
                    </p>
                    <PrimaryButton text={"Download from App Store"} onClick={(e) => {
                        const userAgent = navigator.userAgent.toLowerCase();

                        let downloadLink;
                        if (userAgent.includes('android')) {
                          downloadLink = PlayStoreUrl; // Replace with your Google Play link
                        } else if (userAgent.includes('iphone') || userAgent.includes('ipad')) {
                          downloadLink = AppStoreUrl; // Replace with your App Store link
                        } else {
                          downloadLink = AppStoreUrl;
                        }

                        logEvent(analytics, 'download_clicked');
                        openInNewTab(downloadLink);
                    }} />
                </div>
                <div className="right-content text-right">
                    <SecondaryButton text={"Home"} showArrow={false} onClick={handleHomeClick} />
                    <SecondaryButton text={"Asanas"} showArrow={false} onClick={(e) => {
                        navigate("/asanas")
                    }} />
                    <SecondaryButton text={"Instagram"} showArrow={true} onClick={(e) => {
                        openInNewTab(InstagramUrl)
                    }} />
                    <SecondaryButton text={"Facebook"} showArrow={true} onClick={(e) => {
                        openInNewTab(FacebookUrl)
                    }} />
                    <SecondaryButton text={"Privacy Policy"} showArrow={false} onClick={(e) =>
                        navigate("/privacy-policy?showNavigationBars=true")
                    } />
                    <SecondaryButton text={"Terms of Service"} showArrow={false} onClick={(e) =>
                        navigate("/terms-of-service?showNavigationBars=true")
                    } />
                </div>
            </div>
            <div className="bottom-section flex flex-col items-start text-black font-inter text-base font-normal leading-tight text-left tracking-tight">
                <p className="mb-4">info@sequence-studios.com</p>
                <p className="mb-4">71-75 Shelton Street</p>
                <p className="mb-4">Covent Garden</p>
                <p className="mb-4">WC2H 9JQ</p>
                <p>London, UK</p>
            </div>
        </div>
    );
};

export default Footer;
