import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import {db} from '../App';

export async function fetchPosePublicData(id) {
    const docRef = doc(db, "/training/v0/yoga/v0/posesPublicData", id);
    const docSnap = await getDoc(docRef);
    // @ts-ignore
    const posePublicData = docSnap.data();
    return posePublicData
}

export async function fetchPosesPublicData() {
    const querySnapshot = await getDocs(collection(db, "/training/v0/yoga/v0/posesPublicData"));
    const tmpPosesPublicData = querySnapshot.docs.map(doc => {
        // @ts-ignore
        const posePublicData = doc.data();
        return posePublicData;
    });
      
    return tmpPosesPublicData
}

