export const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) {
        newWindow.opener = null;
    }
};

export const openInAppBanner = {
    title: '👆 Tap the banner to open this page in the app 👆',
    subtitle: 'If you don\'t see the banner, try reopening this page from your phone after you\'ve installed the Sequence app',
};

Array.prototype.randomElement = function () {
    if (this.length === 0) {
        return undefined; // Handle empty array case
    }
    const randomIndex = Math.floor(Math.random() * this.length);
    return this[randomIndex];
};

Array.prototype.shuffle = function() {
    for (let i = this.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [this[i], this[j]] = [this[j], this[i]]; // Swap elements
    }
    return this;
};