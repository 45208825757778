// SEO.js
import React from 'react';
import {Helmet} from 'react-helmet';

const SEO = ({title, description, canonicalUrl, keywords}) => {
    const allKeywords = "yoga, health, wellness, yoga instructor, " + (keywords || "");

    return (
        <Helmet>
            <title>{title}</title>
            {description && <meta name="description" content={description} />}
            {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
            <meta name="keywords" content={allKeywords} />
        </Helmet>
    );
};

export default SEO;
