import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import LogoImage from '../resources/images/sequence-logo-with-text.png';
import PrimaryButton from './PrimaryButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { openInNewTab } from '../helpers';
import { AppStoreUrl, PlayStoreUrl } from '../constants';
import {logEvent} from 'firebase/analytics';
import {analytics} from '../App';

const Navbar = ({ banner }) => {
  const isIOS = () => {
    return /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
  };
  
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();

  const handleHomeClick = () => {
    logEvent(analytics, 'home_clicked');

    if (location.pathname === '/') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      navigate('/');
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;

      const isScrollingDownwards = !(prevScrollPos > currentScrollPos);
      // setVisible(!isScrollingDownwards); // Show Navbar if at top or scrolling up

      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  return (
    <div className='fixed top-0 left-0 right-0 z-50'>
      {isIOS() && banner && (
        <div className="bg-blue-500 text-white p-4 text-center">
          <h1 className="text-sm md:text-xl font-bold mb-2">{banner.title}</h1>
          <p className="text-xs md:text-sm">{banner.subtitle}</p>
        </div>
      )}
      <nav
        className={`bg-white flex justify-between items-center py-4 px-4 md:px-20 transition-transform duration-300 z-50 ${
          visible ? 'transform translate-y-0' : 'transform -translate-y-full'
        }`}
      >
        <img
          src={LogoImage}
          alt="Logo"
          className="w-[165px] h-[41.2px] cursor-pointer"
          onClick={handleHomeClick}
        />
        <PrimaryButton text={"Download"} onClick={(e) => {
          const userAgent = navigator.userAgent.toLowerCase();

          let downloadLink;
          if (userAgent.includes('android')) {
            downloadLink = PlayStoreUrl; // Replace with your Google Play link
          } else if (userAgent.includes('iphone') || userAgent.includes('ipad')) {
            downloadLink = AppStoreUrl; // Replace with your App Store link
          } else {
            downloadLink = AppStoreUrl;
          }

          logEvent(analytics, 'download_clicked');
          openInNewTab(downloadLink);
        }} />
      </nav>
    </div>
  );
};

Navbar.propTypes = {
  banner: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
  }),
};

export default Navbar;
