import React from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import TopBannerImage from '../resources/images/top-banner.webp';
import SecondBannerImage from '../resources/images/second-banner.webp';
import AddAsanaImage from '../resources/images/add-asana.avif';
import EasyMorningFlowImage from '../resources/images/easy-morning-flow.avif';
import SalutationToTheSunImage from '../resources/images/108-salutations.avif';
import ShareSequenceImage from '../resources/images/printed-sequence.avif';
import VerticalImageTextComponent from '../components/VerticalImageTextComponent';
import HorizontalImageTextComponent from '../components/HorizontalImageTextComponent';
import {useNavigate} from 'react-router-dom';
import SEO from '../components/SEO';

const Home = () => {
    const navigate = useNavigate();

    return (
        <div className="sequence-desktop">
            <SEO
                title="Home - Join our community-driven platform!"
                description="Elevate your yoga journey with our app! Explore asanas, create unique sequences, and track their stats. Join our community-driven platform!"
                keywords="asanas, yoga poses, yoga sequences yoga sequence builder, yoga sequence player, export yoga sequence"
                canonicalUrl="https://www.sequence-studios.com/"
            />
            <NavBar />
            <div className="main-section flex flex-col items-center px-4 md:px-20 py-20">
                <div className="for-yogis-by-yogis w-full text-left font-inter text-base font-normal leading-tight py-12 tracking-tight">
                    For yogis, by yogis
                </div>
                <div className="yoga-your-way-section w-full text-left font-inter text-3xl md:text-7xl font-medium leading-tight py-5 tracking-tight mb-12">
                    <p>
                        <span className="text-black">Yoga, your way.</span>{' '}
                        <span className="text-gray-500">Asanas and sequences for yoga instructors</span>
                    </p>
                </div>

                <VerticalImageTextComponent
                    imageSrc={TopBannerImage}
                    text={"We often found ourselves frustrated by the scattered sources for accurate asana information and the lack of tools for building effective sequences.\n\nSo we created Sequence, to offer a robust database of verified asanas and an intuitive sequence builder, and to build a vibrant yoga community where instructors and enthusiasts worldwide can share sequences as well as experiences. "}
                />
                <VerticalImageTextComponent
                    imageSrc={SecondBannerImage}
                    title={"Asana library"}
                    subtitle={"A robust collection of asanas, combos and sequences along with their details"}
                    button={{
                        text: "Explore more asanas", action: () => {
                            navigate("/asanas")
                        }
                    }}
                />
                <HorizontalImageTextComponent
                    title="Build a sequence"
                    subtitle="Create your own sequences from a large library of poses and combos"
                    imageSrc={AddAsanaImage}
                    reverse={false}
                />
                <HorizontalImageTextComponent
                    title="Enjoy your sequences"
                    subtitle="Play it alone at home, or bring to your class"
                    imageSrc={EasyMorningFlowImage}
                    reverse={false}
                />
                <HorizontalImageTextComponent
                    title="Check the stats"
                    subtitle="Once your sequences get created check their stats for more info on what you're building"
                    imageSrc={SalutationToTheSunImage}
                    reverse={false}
                />
                <HorizontalImageTextComponent
                    title="Share your sequences"
                    subtitle="Create images from your sequences with one tap, and share them with your friends"
                    imageSrc={ShareSequenceImage}
                    reverse={false}
                />
            </div>
            <Footer />
        </div>
    );
};

export default Home;
