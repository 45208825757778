import React from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';

const ErrorPage = () => {
    return (
        <div className="sequence-desktop">
            <NavBar />
            <div className="main-section flex flex-col items-center px-4 md:px-20 py-20">
                <div className="yoga-your-way-section w-full text-left font-inter text-7xl font-medium leading-tight py-5 tracking-tight mb-12">
                    <p className="text-black">
                        404
                    </p>
                    <p>
                        Something went wrong
                    </p>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ErrorPage;
