import React, { useState } from 'react';
import ArrowRight from './ArrowRight';
import ArrowUpRight from './ArrowTopRight';

const PrimaryButton = ({ text, onClick }) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <button
            className="flex items-center px-6 py-2 bg-black text-white border-none rounded-3xl h-10 cursor-pointer transition duration-300 ease-in-out hover:bg-turquoise hover:text-black"
            onClick={onClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <span className="font-inter text-base font-medium leading-tight text-left">
                {text}
            </span>
            <div className="ml-1 w-auto h-5">
                {isHovered ? <ArrowRight /> : <ArrowUpRight />}
            </div>
        </button>
    );
};

export default PrimaryButton;
