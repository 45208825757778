import React, {useEffect, useState} from 'react';
import Navbar from '../components/NavBar';
import Footer from '../components/Footer';
import {openInAppBanner} from '../helpers';
import Card from '../components/Card';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {collection, getDocs} from 'firebase/firestore';
import {db} from '../App';
import {useNavigate} from 'react-router-dom';
import {useData} from '../components/DataProvider';
import SEO from '../components/SEO';

const PosesPage = () => {
    const navigate = useNavigate();
    const {data, setData} = useData();

    useEffect(() => {
        const fetchPosesPublicData = async () => {
            const querySnapshot = await getDocs(collection(db, "/training/v0/yoga/v0/posesPublicData"));
            const posesPublicData = querySnapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));
            setData({posesPublicData: posesPublicData});
        };

        // setData({posesPublicData: null});

        fetchPosesPublicData();
    }, [setData]);

    // const isIOS = () => {
    //     return /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    // };

    const responsive = {
        superLargeDesktop: {
            breakpoint: {max: 4000, min: 1600},
            items: 4,
            slidesToSlide: 4,
        },
        desktop: {
            breakpoint: {max: 1600, min: 768},
            items: 3,
            slidesToSlide: 3,
        },
        tablet: {
            breakpoint: {max: 768, min: 480},
            items: 2,
            slidesToSlide: 2,
        },
        mobile: {
            breakpoint: {max: 480, min: 0},
            items: 1,
            slidesToSlide: 1,
        }
    };

    const rotateDegrees = [];
    for (let i = -3; i <= 3; i += 0.5) {
        rotateDegrees.push(i);
    }

    const rotateValues = rotateDegrees.map((el) => `${el}`)

    return (
        <div className='pose-page'>
            <SEO
                title="Asanas - Explore detailed information on various asanas!"
                description="Explore detailed information on asanas with insights into pose types, themes, tags, benefits, chakras, and variations. View each asana for more info."
                keywords="asanas, yoga poses, yoga benefits, yoga chakras"
                canonicalUrl="https://www.sequence-studios.com/asanas"
            />
            <Navbar banner={openInAppBanner} />
            <h1 className="sr-only">Asanas</h1>

            <div className="pt-30 md:pt-20 pb-20 flex flex-col">

                <div>
                    <h1 className='pb-4 px-4 md:px-20 text-black font-inter font-medium text-lg leading-relaxed text-left whitespace-pre-line'>Download the Sequence App to Unlock More!</h1>
                    <p className='pb-10 px-4 md:px-20 font-inter text-base font-light leading-relaxed text-left whitespace-pre-line'>Get access to comprehensive information on asanas that goes beyond what's available on the web. Explore detailed insights on chakras, benefits, and much more within the app.</p>
                </div>

                {
                    (data.posesPublicData || []).length === 0 ? null :
                        <Carousel
                            className='flex px-4 md:px-20 py-8'
                            responsive={responsive}
                            centerSlidePercentage={50}
                            swipeable={true}
                            draggable={true}
                            showDots={false}
                            infinite={false}
                        >
                            {
                                data.posesPublicData.map((pose, index) =>
                                    <div className="pr-8 md:p-0" key={pose.id}>
                                        <Card
                                            key={pose.id}
                                            posePublicData={pose}
                                            rotate={rotateValues.randomElement()}
                                            onClick={() => {
                                                navigate(`/asana/${pose.id}`, {state: {posePublicData: pose}});
                                            }}
                                        />
                                    </div>

                                )
                            }

                        </Carousel>
                }
            </div>
            <Footer />
        </div>
    );
};

export default PosesPage;
